<template>
  <v-row
    justify="center"
    align="center"
    class="pa-0 ma-0 customer_signup__page"
  >
    <div style="position: absolute; top: 1rem; left: 1rem" class="wwwx">
      <router-link to="/">
        <img
          alt="CodeDrills Logo"
          width="108"
          :src="require('../../assets/images/CodeDrillsLight.svg')"
        />
      </router-link>
    </div>
    <v-col cols="8" v-if="$vuetify.breakpoint.mdAndUp">
      <v-card
        elevation="0"
        class="customer_signup__carousel"
        height="100%"
        dark
      >
        <Carousel :slides="slides" />
      </v-card>
    </v-col>
    <v-col
      cols="12"
      md="4"
      style="height: 100%"
      class="
        rounded-tl-xl rounded-bl-xl
        d-flex
        flex-column
        justify-center
        customer_signup__card
      "
    >
      <SignUpForm />
    </v-col>
  </v-row>
</template>
<script>
import SignUpForm from "../../components/campaign/signup/SignupForm.vue";
import Carousel from "../../components/campaign/signup/Carousel.vue";
export default {
  data() {
    return {
      slides: [
        {
          image: "campaign/evaluate.png",
          title: "Evaluate candidates based on skills and test",
          subtitle: "",
        },
        {
          image: "campaign/skilled.png",
          title: "Make skilled tests for the candidates",
          subtitle: "",
        },
        {
          image: "campaign/best.png",
          title: "Recruit the Best candidate for any profile ",
          subtitle: "",
        },
      ],
    };
  },
  components: {
    SignUpForm,
    Carousel,
  },
};
</script>
<style scoped>
.customer_signup__page {
  background-color: #122333;
  padding-top: 13px;
  padding-bottom: 13px;
  height: 100%;
}
.customer_signup__card {
  background-color: white;
}
.customer_signup__carousel {
  background-color: #122333;
}
</style>
