var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-5",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_vm.successfulInvite ? _c('v-card', {
    attrs: {
      "elevation": "0"
    }
  }, [_c('v-card-title', {
    staticClass: "text-h5 justify-center"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "color": "primary",
      "large": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.mdiCheckDecagram) + " ")]), _c('span', [_vm._v(" Check your email for test invite ")])], 1)], 1) : _c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    },
    model: {
      value: _vm.form,
      callback: function callback($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }, [_c('v-card', {
    attrs: {
      "elevation": "0"
    }
  }, [_c('v-card-title', [_vm._v(" Register for Test ")]), _c('v-card-text', [_c('v-text-field', {
    attrs: {
      "label": "Name",
      "placeholder": "Enter Name",
      "outlined": "",
      "rules": [function (v) {
        return !!v || 'Name is required';
      }]
    },
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Email",
      "placeholder": "email@domain.com",
      "outlined": "",
      "rules": _vm.emailRules
    },
    model: {
      value: _vm.email,
      callback: function callback($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  })], 1), _c('v-card-actions', {
    staticClass: "justify-center pb-8"
  }, [_c('v-btn', {
    staticClass: "\n              rounded-md\n              text-capitalize\n              pa-5\n              white--text\n              font-weight-medium\n              text-h6\n            ",
    attrs: {
      "color": "success",
      "disabled": !_vm.form,
      "loading": _vm.loading
    },
    on: {
      "click": _vm.validateForm
    }
  }, [_vm._v(" Submit ")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }