<template>
  <v-row align="center" justify="center">
    <v-col cols="12" md="6" class="pa-5">
      <v-card v-if="successfulInvite" elevation="0">
        <v-card-title class="text-h5 justify-center">
          <v-icon color="primary" large class="mr-2">
            {{ mdiCheckDecagram }}
          </v-icon>
          <span> Check your email for test invite </span>
        </v-card-title>
      </v-card>
      <v-form v-model="form" ref="form" lazy-validation v-else>
        <v-card elevation="0">
          <v-card-title> Register for Test </v-card-title>
          <v-card-text>
            <v-text-field
              label="Name"
              v-model="name"
              placeholder="Enter Name"
              outlined
              :rules="[(v) => !!v || 'Name is required']"
            >
            </v-text-field>
            <v-text-field
              label="Email"
              v-model="email"
              placeholder="email@domain.com"
              outlined
              :rules="emailRules"
            >
            </v-text-field>
          </v-card-text>
          <v-card-actions class="justify-center pb-8">
            <v-btn
              @click="validateForm"
              class="
                rounded-md
                text-capitalize
                pa-5
                white--text
                font-weight-medium
                text-h6
              "
              color="success"
              :disabled="!form"
              :loading="loading"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { mdiCheckDecagram } from "@mdi/js";
export default {
  data() {
    return {
      mdiCheckDecagram,
      form: false,
      name: "",
      email: "",
      emailRules: [
        (v) => !!v || "Email needed",
        (v) => /.+@.+/.test(v) || "Email must be valid",
      ],
      successfulInvite: false,
      loading: false,
    };
  },
  methods: {
    ...mapActions("recruiter", ["testInviteByUrl"]),
    validateForm() {
      this.$refs.form.validate() && this.invite();
    },
    invite() {
      this.loading = true;
      this.testInviteByUrl({
        name: this.name,
        email: this.email,
        url: this.$route.params.testurl,
      })
        .then((_) => {
          this.successfulInvite = true;
        })
        .catch((err) => {
          this.loading = false;
          this.$store.dispatch("notifs/addNotif", {
            text:
              err.message || `Error Inviting ${this.name}, please try again!`,
            type: "error",
          });
        });
    },
  },
  created() {
    console.log("id", this.$route.params.testurl);
  },
};
</script>