<template>
  <v-card elevation="0" class="px-8">
    <v-card-title class="text-h5 text-lg-h4 text-xl-h3 pb-8 font-weight-bold">
      Create Account
    </v-card-title>
    <v-card-text>
      <div class="google__signup">
        <v-btn
          width="100%"
          @click.prevent="validateGoogleSignUp"
          elevation="0"
          class="py-5 text-none"
          style="border: 1px solid #c4c4c4; background: white"
        >
          <span
            class="pt-2 pr-3 text-subtitle-1"
            v-html="coloredGoogleIcon"
          ></span>
          Sign up with Google
        </v-btn>
      </div>
      <div class="divider py-6">OR</div>
      <v-form ref="signUpRef" v-model="signUpForm" lazy-validation>
        <v-text-field
          background-color="white"
          placeholder="Name"
          v-model="fullName"
          :rules="[(v) => !!v || 'Name is required']"
        >
        </v-text-field>
        <v-text-field
          background-color="white"
          placeholder="Work Email"
          v-model="workEmail"
          :rules="emailRules"
        >
        </v-text-field>
      </v-form>
      <v-checkbox
        v-model="agreeToTOS"
        :error-messages="TOSError"
        @change="validateTOS"
        :rules="[(v) => !!v || 'You must agree to continue!']"
      >
        <template v-slot:label>
          Agree to&nbsp;
          <router-link to="/terms-of-service" class="danger--text">
            Terms and Conditions
          </router-link>
        </template>
      </v-checkbox>
      <v-card-actions class="flex-column">
        <v-btn
          @click.prevent="validateSignUpForm"
          class="rounded-md text-capitalize pa-5 white--text font-weight-medium text-h6"
          width="100%"
          color="#2EAC68"
          :disabled="!signUpForm"
          :loading="signingUp"
        >
          Sign Up
        </v-btn>
        <div class="align-self-start mt-8">
          Already have an account?
          <router-link to="/login">Login.</router-link>
        </div>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>
<script>
import { mdiGoogle } from "@mdi/js";
import firebase from "firebase";
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("customer", ["customerSignUp"]),
    validateTOS() {
      if (!this.agreeToTOS) {
        this.TOSError.push("You must agree to continue!");
        return false;
      } else {
        this.TOSError = [];
        return true;
      }
    },
    validateSignUpForm() {
      this.$refs.signUpRef.validate() && this.validateTOS() && this.doSignUp();
    },
    validateGoogleSignUp() {
      this.validateTOS() && this.signUpWithGoogle();
    },
    signUpWithGoogle() {
      this.signingUp = true;
      const provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope("https://www.googleapis.com/auth/userinfo.email");
      provider.addScope("https://www.googleapis.com/auth/userinfo.profile");

      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          /** @type {firebase.auth.OAuthCredential} */
          // The signed-in user info.
          const user = result.user;
          // after getting user, signup to backend
          result.user
            .updateProfile({
              displayName: this.fullName,
            })
            .then((r) => {
              this.customerSignUp({
                name: user.displayName,
                email: user.email,
              })
                .then((r) => {
                  this.$store.dispatch("notifs/addNotif", {
                    text: `Successfully Signed Up!`,
                    type: "success",
                  });
                  this.$router.push("/dashboard");
                })
                .catch((err) => {
                  this.$store.dispatch("notifs/addNotif", {
                    text: err.message || `Error Signing Up, try again later!`,
                    type: "error",
                  });
                  this.signingUp = false;
                });
            });
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.email;
          // The firebase.auth.AuthCredential type that was used.
          if (error.code === "auth/email-already-in-use") {
            this.$store.dispatch("notifs/addNotif", {
              text: `Email address already in use, please login`,
              type: "info",
            });
            this.$router.push("/login");
          } else {
            this.$store.dispatch("notifs/addNotif", {
              text:
                error.message ||
                `Error while signing up, please retry after sometime!`,
              type: "info",
            });
            this.signingUp = false;
          }
        });
    },
    generatePass() {
      let chars =
        "0123456789abcdefghijklmnopqrstuvwxyz-ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      let pass = "";
      for (let i = 0; i < 24; i++) {
        pass += chars[Math.floor(Math.random() * chars.length)];
      }
      return pass;
    },
    doSignUp() {
      this.signingUp = true;
      firebase
        .auth()
        .createUserWithEmailAndPassword(this.workEmail, this.generatePass())
        .then((res) => {
          // set name then call api
          res.user
            .updateProfile({
              displayName: this.fullName,
            })
            .then((_) => {
              this.customerSignUp({
                name: this.fullName,
                email: this.workEmail,
              })
                .then((r) => {
                  this.$store.dispatch("notifs/addNotif", {
                    text: `Successfully Signed Up!`,
                    type: "success",
                  });
                  this.$router.push("/dashboard");
                })
                .catch((err) => {
                  this.$store.dispatch("notifs/addNotif", {
                    text: err.message || `Error Signing Up, try again later!`,
                    type: "error",
                  });
                  this.signingUp = false;
                });
            });
        })
        .catch((error) => {
          if (error.code === "auth/email-already-in-use") {
            this.$store.dispatch("notifs/addNotif", {
              text: `Email address already in use, please login`,
              type: "info",
            });
            this.$router.push("/login");
          } else {
            this.$store.dispatch("notifs/addNotif", {
              text: error.message,
              type: "error",
            });
            this.signingUp = false;
          }
        });
    },
  },
  created() {
    // console.log(`currUser:`, firebase.auth().currentUser, this.mdiGoogle);
  },
  data() {
    return {
      mdiGoogle,
      coloredGoogleIcon:
        '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="36" height="36" preserveAspectRatio="xMidYMid meet" viewBox="0 0 48 48"><rect x="0" y="0" width="48" height="48" fill="none" stroke="none" /><path fill="#FFC107" d="M43.611 20.083H42V20H24v8h11.303c-1.649 4.657-6.08 8-11.303 8c-6.627 0-12-5.373-12-12s5.373-12 12-12c3.059 0 5.842 1.154 7.961 3.039l5.657-5.657C34.046 6.053 29.268 4 24 4C12.955 4 4 12.955 4 24s8.955 20 20 20s20-8.955 20-20c0-1.341-.138-2.65-.389-3.917z"/><path fill="#FF3D00" d="M6.306 14.691l6.571 4.819C14.655 15.108 18.961 12 24 12c3.059 0 5.842 1.154 7.961 3.039l5.657-5.657C34.046 6.053 29.268 4 24 4C16.318 4 9.656 8.337 6.306 14.691z"/><path fill="#4CAF50" d="M24 44c5.166 0 9.86-1.977 13.409-5.192l-6.19-5.238A11.91 11.91 0 0 1 24 36c-5.202 0-9.619-3.317-11.283-7.946l-6.522 5.025C9.505 39.556 16.227 44 24 44z"/><path fill="#1976D2" d="M43.611 20.083H42V20H24v8h11.303a12.04 12.04 0 0 1-4.087 5.571l.003-.002l6.19 5.238C36.971 39.205 44 34 44 24c0-1.341-.138-2.65-.389-3.917z"/></svg>',
      agreeToTOS: undefined,
      TOSError: [],
      signUpForm: false,
      fullName: "",
      workEmail: "",
      emailRules: [
        (v) => !!v || "Email needed",
        (v) => /.+@.+/.test(v) || "Email must be valid",
      ],
      signingUp: false,
    };
  },
};
</script>
<style scoped>
.divider {
  display: flex;
  flex-direction: row;
  color: #909090;
}
.divider:before,
.divider:after {
  content: "-";
  margin: auto;
}
.divider:before {
  margin-right: 10px;
}
.divider:after {
  margin-left: 10px;
}
a {
  color: #2eac68;
  text-decoration-line: none;
}
</style>
